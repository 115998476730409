import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IsMobilePipe} from './is-mobile.pipe';
import { ImageResponsivePipePipe } from './image-resposive.pipe';


@NgModule({
  declarations: [IsMobilePipe, ImageResponsivePipePipe],
  imports: [
    CommonModule,
  ], exports: [IsMobilePipe, ImageResponsivePipePipe],
})
export class MobileModule {
}
