import {Component, OnInit, Renderer2} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fadeInOut', [

      state('open', style({
        //top: '0px',
        background: 'rgba(255,255,255,1)',
        // height: '70px',
        'box-shadow': '0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)'
      })),
      state('closed', style({
        // top: '-100px',
        // opacity: 0,
        background: 'rgba(52, 59, 64, 0)',
        // height: '80px'


      })),
      transition('open => closed', [
        animate('0.4s')
      ]),
      transition('closed => open', [
        animate('0.4s')
      ]),

      // state('void', style({
      //     opacity: 0,
      //     top: '-100px',
      // })),
      // transition('void <=> *', animate(700)),
    ]),

  ]
})
export class HeaderComponent implements OnInit {
  navbarIsOpen: boolean;

  constructor(private render: Renderer2) {
  }

  ngOnInit() {
  }


  openig(status: boolean) {
    this.navbarIsOpen = status;
   }
}
