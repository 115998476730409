import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ToggleNavBarService} from './services/toggle-nav-bar.service';
import {WINDOW} from './core/services/window.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  toggle$: Observable<boolean>;
  title = 'SDS';
  ua = navigator.userAgent;

  constructor(private toggleService: ToggleNavBarService, @Inject(WINDOW) private window: Window) {

  }

  ngOnInit(): void {
    this.toggle$ = this.toggleService.getToggle();
  }


  onActivate($event: any) {
    this.window.scroll(0, 0);
  }

  //
  // mobile() {
  //   const ua = navigator.userAgent;
  //
  //   if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
}
