import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderModule} from './components/header/header.module';
import {FooterModule} from './components/footer/footer.module';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material';
import {DevopsComponent} from './components/devops/devops.component';
import {ShellComponent} from './shell/shell.component';

import {HttpService} from './services/http-service.service';
import {HttpClientModule} from '@angular/common/http';
import {IsMobilePipe} from './shared/pipes/mobile/is-mobile.pipe';
import {MobileModule} from './shared/pipes/mobile/mobile.module';
import {Footer2Module} from './components/footer2/footer2.module';
import {WINDOW_PROVIDERS} from './core/services/window.service';


@NgModule({
  declarations: [
    AppComponent,
    DevopsComponent,
    ShellComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    BrowserAnimationsModule,
    HeaderModule,
    HttpClientModule,
    MatSidenavModule,
    FooterModule,
    Footer2Module,
    MobileModule,
  ],
  providers: [HttpService, WINDOW_PROVIDERS],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}
