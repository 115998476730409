import {Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {WINDOW} from '../../../core/services/window.service';

@Directive({
  selector: '[appScroll]',
  exportAs: 'appScroll',
})
export class ScrollDirective implements OnInit, OnDestroy {
  @Output() vis = new EventEmitter<boolean>(true);
  @Input() notifyOn = -79;

  constructor(private elementRef: ElementRef, private render: Renderer2, @Inject(WINDOW) private window: Window) {
  }

  ngOnInit(): void {
    this.vis.emit(false);
    this.window.addEventListener('scroll', this.scroll, true);
  }

  ngOnDestroy(): void {
    this.window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (): void => {
    // 
    // 
    const elPosition = this.elementRef.nativeElement.getBoundingClientRect();
    if (elPosition.top <= this.notifyOn) {
      this.vis.emit(true);
    } else {
      this.vis.emit(false);
    }

  };


}
