import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ShellComponent} from './shell/shell.component';
import {CustomPreloadingStrategyService} from './shared/services/custom-preloading-strategy.service';


const routes: Routes = [

  {
    path: '',

    component: ShellComponent,
    children: [
      //{path: 'nearshoring', loadChildren: './pages/nearshoring/nearshoring.module#NearshoringModule', data: {preload: 2}},
      {path: 'home', loadChildren: './pages/home/home.module#HomeModule', data: {preload: 2}},
      {path: 'hw-work', loadChildren: './pages/hw-work/hw-work.module#HwWorkModule', data: {preload: 2}},
      {path: 'about-us', loadChildren: './pages/about-us/about-us.module#AboutUsModule', data: {preload: 1}},
      {path: 'contact-us', loadChildren: './pages/contact-us/contact-us.module#ContactUsModule', data: {preload: 2}},
      {path: '', redirectTo: 'home', pathMatch: 'full', data: {preload: 2}}
    ],
  },
  {path: 'job', loadChildren: './pages/job/job.module#JobModule'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: CustomPreloadingStrategyService})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
