import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isMobile'
})
export class IsMobilePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(value)) {
      return true;
    } else {
      return false;
    }
  }

}
