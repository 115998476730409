import {Injectable} from '@angular/core';
import {PreloadingStrategy, Route, Router} from '@angular/router';
import {EMPTY, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomPreloadingStrategyService implements PreloadingStrategy {
  preloadedModules: string[] = [];

  constructor(private router: Router) {
  }

  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    if (!this.router.url.toLocaleLowerCase().includes('job') &&
      route.data &&
      route.data.preload) {
      this.preloadedModules.push(route.path);
      return fn();
    } else {
      return EMPTY;
    }
  }
}
