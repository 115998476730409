import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Footer2Component} from './footer2.component';
import {MatButtonModule, MatIconModule} from '@angular/material';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [Footer2Component],
  exports: [
    Footer2Component
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    RouterModule
  ]
})
export class Footer2Module {
}
