import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToggleNavBarService {

  _toggle$ = new BehaviorSubject<boolean>(false);

  constructor() {
  }


  getToggle = (): Observable<boolean> => {
    return this._toggle$.asObservable();
  }

  setToggle(bool : boolean){
    this._toggle$.next(bool);
  }
}
