import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'imageOnDevise',
})
export class ImageResponsivePipePipe implements PipeTransform {

  transform(path: string, ...args: any[]): any {
    if (path && path.lastIndexOf('svg') === -1) {
      const lastIndex = path.lastIndexOf('/');
      if (window.innerWidth < 500) {
        return `${path.substr(0, lastIndex)}/min${path.substr(lastIndex, path.length - 1)}`;
      } else if (window.innerWidth < 1000) {
        return `${path.substr(0, lastIndex)}/mid${path.substr(lastIndex, path.length - 1)}`;
      } else {
        return `${path.substr(0, lastIndex)}/max${path.substr(lastIndex, path.length - 1)}`;
      }
    }
    return path;
  }

}
