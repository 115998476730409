import {Component, Inject, OnInit} from '@angular/core';
import {WINDOW} from '../core/services/window.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {

  constructor(@Inject(WINDOW) private window: Window) {
  }

  ngOnInit() {
  }

  onActivate($event: any) {
    this.window.scroll(0, 0);
  }
}
